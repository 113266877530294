<template>
  <div>
    <div v-if="isLoading" class="loader-wrapper">
      <span class="loader"></span>
    </div>
    <main v-else class="registration">
      <form class="registration__form">
        <h1 class="registration__form-title">Авторизация</h1>
        <div class="registration__form-body">
          <div class="registration__form-field">
            <input
              type="text"
              placeholder="E-mail"
              class="form-control"
              v-model.trim="user.email"
              :class="{ 'form-control--error': $v.user.email.$error }"
            />
          </div>
          <div class="registration__form-field">
            <input
              type="password"
              placeholder="Пароль"
              class="form-control"
              v-model.trim="user.password"
              :class="{ 'form-control--error': $v.user.password.$error }"
            />
          </div>
          <div class="registration__form-link as-end">
            <a href="https://simpla.fit/password-recovery" class="button button-link">
              Забыли пароль?
            </a>
          </div>
        </div>
        <div class="registration__form-button">
          <a href="javascript:void(0)" class="button button-accent-1" @click="login">Войти</a>
        </div>
        <div class="registration__form-row">
          <p class="registration__form-text">Не зарегистрированы?</p>
          <div class="registration__form-link">
            <router-link to="/" class="button button-link">Создать аккаунт</router-link>
          </div>
        </div>
      </form>
    </main>
  </div>
</template>

<script>
import errorHandlerForResponse from '@/helpers/errorHandlerForResponse'
import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  name: 'ProductEdit',
  validations: {
    user: {
      password: {
        required
      },
      email: {
        required,
        email
      }
    }
  },
  data() {
    return {
      user: {
        email: '',
        password: ''
      },
      isLoading: false
    }
  },
  methods: {
    ...mapActions('auth', { loginAction: 'loginWithHeaders' }),
    async login() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$toast.error('Заполните все поля')
      } else {
        this.isLoading = true
        let payload = {
          user: this.user,
          password: this.password
        }
        await this.loginAction(payload)
          .then(() => {
            this.$router.push('/account/statistic')
          })
          .catch(e => {
            this.errorHandlerForResponse(e)
            this.isLoading = false
          })
      }
    },
    errorHandlerForResponse
  },
  mounted() {
    if (this.$route.query.email) {
      this.user.email = this.$route.query.email
    }
  }
}
</script>

<style>
.form-control--error {
  border-color: red !important;
}
.form-control:hover {
  border-width: 1px !important;
}
.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 127px);
}
@media screen and (max-width: 760px) {
  .registration__form-button {
    width: 100% !important;
  }
  .loader-wrapper {
    height: calc(100vh - 163px);
  }
}
</style>
