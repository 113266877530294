<template>
  <main class="registration">
    <form class="registration__form">
      <h1 class="registration__form-title">Присоединиться к клубу</h1>
      <div class="registration__form-body">
        <div class="registration__form-field">
          <input
            type="text"
            placeholder="E-mail"
            class="form-control"
            v-model.trim="user.email"
            :class="{ 'form-control--error': $v.user.email.$error }"
          />
        </div>
        <div class="registration__form-field">
          <input
            type="password"
            placeholder="Пароль"
            class="form-control"
            v-model.trim="user.password"
            :class="{ 'form-control--error': $v.user.password.$error }"
          />
        </div>
        <div class="registration__form-field">
          <input
            type="text"
            placeholder="Код приглашения"
            class="form-control"
            v-model.trim="club.invite"
            :class="{ 'form-control--error': $v.club.invite.$error }"
          />
        </div>
      </div>
      <div class="registration__form-button">
        <a href="javascript:void(0)" class="button button-accent-1" @click="register" v-if="!isLoading">
          Присоединиться
        </a>
        <a href="javascript:void(0)" class="button button-accent-1" v-else>Отправляем...</a>
      </div>
      <div class="registration__form-link">
        <a href="https://simpla.fit/password-recovery" class="button button-link">
          Забыли пароль?
        </a>
      </div>
    </form>
  </main>
</template>

<script>
import errorHandlerForResponse from '@/helpers/errorHandlerForResponse'
import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  name: 'ProductEdit',
  validations: {
    user: {
      password: {
        required
      },
      email: {
        required,
        email
      }
    },
    club: {
      invite: {
        required
      }
    }
  },
  data() {
    return {
      user: {
        email: '',
        password: ''
      },
      club: {
        invite: ''
      },
      isLoading: false
    }
  },
  methods: {
    ...mapActions('auth', {
      loginAction: 'login',
      setClub: 'setClub',
      checkEmail: 'checkEmail'
    }),
    async register() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$toast.error('Заполните все поля')
      } else {
        this.isLoading = true
        let payload = {
          user: this.user,
          club: this.club
        }
        await this.checkEmail(payload)
          .then(async () => {
            if (this.$store.getters['auth/info']) {
              if (this.$store.getters['auth/info'].user && this.$store.getters['auth/info'].club) {
                this.$toast.success(
                  '<strong>Вы уже зарегистрированы в приложении Simpla и клубе ФНС ЛАБ СПОРТ.</strong> <br /></br> Вы можете использовать приложение Simpla для записи своих тренировок. Они будут автоматически учитываться в статистике клуба и вашем рейтинге.',
                  { duration: 10000 }
                )
                this.isLoading = false
                return true
              }
            }
            await this.loginAction(payload)
              .then(() => {
                this.setClub(this.club)
                  .then(() => {
                    this.$router.push('/success?auth=true')
                  })
                  .catch(e => {
                    this.errorHandlerForResponse(e)
                  })
              })
              .catch(e => {
                this.errorHandlerForResponse(e)
              })
            this.isLoading = false
          })
          .catch(async e => {
            this.errorHandlerForResponse(e)
            this.isLoading = false
          })
      }
    },
    errorHandlerForResponse
  },
  mounted() {
    if (this.$route.query.email) {
      this.user.email = this.$route.query.email
    }
    if (this.$route.query.invite) {
      this.club.invite = this.$route.query.invite
    }
  }
}
</script>

<style>
.form-control--error {
  border-color: red !important;
}
.form-control:hover {
  border-width: 1px !important;
}
@media screen and (max-width: 760px) {
  .registration__form-button {
    width: 100% !important;
  }
}
</style>
